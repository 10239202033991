import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';

export default function Tile({
  title,
  children,
  picture,
  imageProps,
  width = 300,
  height = null,
}) {
  return (
    <Box shadow="base" borderRadius={'xl'} bg="white" w={width} h={height}>
      <Flex direction="column" alignItems="center">
        <Image
          src={picture}
          w="100%"
          h="200px"
          objectFit="cover"
          borderTopRadius={'xl'}
          {...imageProps}
        />
        <Box paddingStart={2.5} paddingEnd={2.5} paddingBottom={2.5} w="100%">
          {title && (
            <Box
              fontWeight={'semibold'}
              mt={2}
              textAlign="center"
              color="#B5851F"
            >
              {title}
            </Box>
          )}
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            flexWrap
            gap={4}
            mt={4}
          >
            {children}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
