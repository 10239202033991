import React, { useState, useCallback, useEffect, memo } from 'react';
import isodistance from './isodistance.json';
import {
  GoogleMap,
  useJsApiLoader,
  Polygon,
  DirectionsService,
  DirectionsRenderer,
} from '@react-google-maps/api';

const Map = ({ address, setGeocodeAddress, setDistance }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBRtQylXIirSC7AnsfvD1n_E9nQKcvHGtE',
  });
  // eslint-disable-next-line no-unused-vars
  const [_, setMap] = useState(null);
  const [geocoder, setGeocoder] = useState();
  const [geocodeResult, setGeocodeResult] = useState(null);
  const [directionsResult, setDirectionsResult] = useState(null);
  const center = {
    lat: 48.231667,
    lng: 6.752637,
  };
  useEffect(() => {
    const fetchPosition = async () => {
      if (address) {
        setDirectionsResult(null);
        setDistance(null);
        const { results } = await geocoder.geocode({ address: address });
        if (results && results.length > 0) {
          setGeocodeResult(results[0]);
          setGeocodeAddress(results[0].formatted_address);
        }
      }
    };
    fetchPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const onLoad = useCallback(function callback(map) {
    const geocoder =
      typeof window !== 'undefined' ? new window.google.maps.Geocoder() : null;
    setGeocoder(geocoder);
    setMap(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnmount = useCallback(function callback(_) {
    setMap(null);
  }, []);

  const paths = isodistance.geometry.coordinates[0].map(rawCoordinates => {
    return {
      lat: rawCoordinates[1],
      lng: rawCoordinates[0],
    };
  });

  const polygonOptions = {
    strokeColor: 'gray.900',
    strokeOpacity: 1,
    strokeWeight: 1,
    fillColor: 'gray.900',
    fillOpacity: 0.2,
  };

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={center}
        zoom={
          typeof window !== 'undefined' && window.screen.width >= 1280 ? 9.5 : 9
        }
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          streetViewControl: false,
        }}
      >
        <Polygon paths={paths} options={polygonOptions} />
        {geocodeResult && !directionsResult && (
          <DirectionsService
            options={{
              destination: {
                lat: geocodeResult.geometry.location.lat(),
                lng: geocodeResult.geometry.location.lng(),
              },
              origin: { lat: 48.231667, lng: 6.752637 },
              travelMode: 'DRIVING',
            }}
            callback={response => {
              setDirectionsResult(response);
              setDistance(response.routes?.[0].legs?.[0]?.distance.text);
            }}
          />
        )}
        {directionsResult && (
          <DirectionsRenderer options={{ directions: directionsResult }} />
        )}
      </GoogleMap>
    )
  );
};

export default memo(Map);
